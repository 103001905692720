<template>
  <div class="flex flex-col gap-4">
    <!-- Header -->
    <TextHeader class="border-none pb-0">Mijn Repowered</TextHeader>
    <TabsComponent :tabs />
    <RouterView />
  </div>
</template>

<script setup>
import { TextHeader } from "@repowerednl/ui-component-library"
import TabsComponent from "@/components/navigation/TabsComponent.vue"

const tabs = [
  {
    name: "Instellingen",
    route: "account-settings",
  },
  {
    name: "Facturen",
    route: "account-invoices",
  },
]
</script>
