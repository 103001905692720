<template>
  <div class="flex items-center gap-4 rounded-lg p-4" :class="style.bg">
    <BaseIcon
      :name="style.icon"
      class="shrink-0 text-2xl"
      :class="style.text"
    />
    <slot />
  </div>
</template>

<script setup>
import { computed } from "vue"
import { BaseIcon } from "@repowerednl/ui-component-library"

const props = defineProps({
  color: {
    type: String,
    default: "primary",
  },
  inCard: Boolean,
})

const colors = {
  primary: {
    bg: props.inCard
      ? "bg-primary-100"
      : "border border-primary-400 bg-primary-50",
    text: "text-primary-600",
    icon: "checkbox-circle",
  },
  danger: {
    bg: props.inCard
      ? "bg-danger-100"
      : "border border-danger-400 bg-danger-50",
    text: "text-danger-600",
    icon: "error-warning",
  },
  info: {
    bg: props.inCard ? "bg-info-100" : "border border-info-400 bg-info-50",
    text: "text-info-700",
    icon: "information",
  },
}

const style = computed(() => colors[props.color] ?? colors.primary)
</script>
