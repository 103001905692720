<template>
  <component
    :is="noTooltip ? 'div' : BaseTooltip"
    :description="$p(descriptionKey)"
  >
    <BaseBadge v-bind="$attrs" :class="colorClass[status]">
      <BaseIcon :name="asset.icon" filled />
    </BaseBadge>
  </component>
</template>

<script setup>
import { computed } from "vue"
import $p from "@/services/phraseService.js"
import {
  BaseTooltip,
  BaseBadge,
  BaseIcon,
} from "@repowerednl/ui-component-library"

const props = defineProps({
  asset: {
    type: Object,
    required: true,
  },
  noTooltip: Boolean,
})

defineOptions({
  inheritAttrs: false,
})

const colorClass = {
  unknown: "!bg-gray-100 !text-gray-900",
  Offline: "!bg-gray-100 !text-gray-900",
  Online: "!bg-finance-100 !text-finance-900",
  "Active steering": "!bg-primary-100 !text-primary-900",
  Error: "!bg-danger-100 !text-danger-900",
}

const status = computed(() => {
  return props.asset.status?.status ?? "unknown"
})

const descriptionKey = computed(() => {
  return props.asset.status?.description_key ?? "status-unknown"
})
</script>
