<template>
  <!-- Buttons -->
  <HorizontalScrollWrapper
    ref="scrollWrapperRef"
    class="flex h-full items-center gap-2"
  >
    <!-- Asset buttons -->
    <BaseButton
      v-for="asset in assets"
      :key="asset.id"
      color="secondary"
      small
      :class="{ 'pointer-events-none': !clickable }"
      @click="onAssetClicked(asset)"
    >
      <StatusIndicatorComponent :asset no-tooltip />
      <span class="mx-1">{{ asset.name }}</span>
    </BaseButton>
  </HorizontalScrollWrapper>
</template>

<script setup>
import { ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import useFilterStore from "@/stores/filterStore.js"
import { BaseButton } from "@repowerednl/ui-component-library"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"
import HorizontalScrollWrapper from "@/components/utilities/HorizontalScrollWrapper.vue"

defineProps({
  assets: {
    type: Array,
    required: true,
  },
  clickable: Boolean,
})

const route = useRoute()
const router = useRouter()
const filterStore = useFilterStore()
const scrollWrapperRef = ref()

function onAssetClicked(asset) {
  filterStore.currentMeteringPoint = asset
  router.push({ name: "assets-analysis" })
}

// Reset asset bar scroll position when the route changes
watch(
  () => route.name,
  () => {
    if (scrollWrapperRef.value) {
      scrollWrapperRef.value.resetPosition()
    }
  },
)
</script>
