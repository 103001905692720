<template>
  <div class="flex flex-col gap-4">
    <div
      v-if="hasFlexServices"
      class="3xl:grid-cols-3 grid grid-flow-row-dense grid-cols-1 gap-4 md:grid-cols-2"
    >
      <!-- NOMINATION -->
      <FlexServiceCard
        icon="calendar"
        title="Nominatie"
        :service="flexServices?.NOMINATION?.is_active"
        :class="getRowSpan(flexServices?.NOMINATION)"
      >
        <div
          v-if="flexServices?.NOMINATION?.is_active"
          class="grid content-between gap-4"
          :class="`grid-cols-2 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3`"
        >
          <div
            v-for="(portion, index) in flexServices?.NOMINATION?.portions"
            :key="index"
            class="grid grid-cols-2 rounded-xl border border-gray-100 p-4"
          >
            <CardContent :title="portion * 100 + '%'" :content="'Forecast'">
            </CardContent>
            <div>
              <CardContent
                :title="
                  formatCurrency(
                    flexServices?.NOMINATION?.limit_price_eur_kwh[index],
                  )
                "
                :content="'Bied Prijs'"
              >
              </CardContent>
            </div>
          </div>
        </div>
      </FlexServiceCard>

      <!-- Other services -->
      <FlexServiceCard
        icon="scales"
        title="Onbalans curtailment"
        :service="flexServices?.IMBALANCE_CURTAILMENT?.is_active"
        :service-property="'Grensbedrag'"
        :service-value="flexServices?.IMBALANCE_CURTAILMENT?.strike_price"
        value-format="currency"
        :class="getRowSpan(flexServices?.IMBALANCE_CURTAILMENT)"
      />

      <FlexServiceCard
        icon="time"
        title="FCR"
        :service="flexServices?.FCR?.is_active"
        :service-property="'Capaciteit'"
        :service-value="flexServices?.FCR?.fcr_capacity"
        :class="getRowSpan(flexServices?.FCR)"
      />

      <FlexServiceCard
        icon="align-vertically"
        title="Onbalans handelen"
        :service="flexServices?.IMBALANCE_TRADING?.is_active"
        :class="getRowSpan(flexServices?.IMBALANCE_TRADING)"
      />

      <FlexServiceCard
        icon="barricade"
        title="CBC curtailment"
        :service="flexServices?.CBC_CURTAILMENT?.is_active"
        :class="getRowSpan(flexServices?.CBC_CURTAILMENT)"
      />
    </div>

    <!-- Displayed when the user has not made a selection -->
    <MeteringPointCard v-else />
  </div>
</template>

<script setup>
import { formatCurrency } from "@/services/formatterService.js"
import { computed } from "vue"
import FlexServiceCard from "@/components/panels/FlexServiceCard.vue"
import MeteringPointCard from "@/components/panels/MeteringPointCard.vue"
import useFilterStore from "@/stores/filterStore.js"
import CardContent from "@/components/information/CardContent.vue"

const filterStore = useFilterStore()

const asset = computed(() => {
  return filterStore.currentMeteringPoint?.properties || {}
})

const flexServices = computed(() => {
  const assetData = asset.value || {}
  return assetData.flex_services || {}
})

const hasFlexServices = computed(() => {
  return Object.keys(flexServices.value).length > 0
})

// Calculate row-span based on service properties
const getRowSpan = (service) => {
  if (!service?.is_active) {
    return "row-span-1"
  }

  const additionalProperties = Object.entries(service).filter(
    ([key, value]) => key !== "is_active" && value != null,
  )

  const hasArrayProperty = additionalProperties.some(([, value]) =>
    Array.isArray(value),
  )

  // Determine row span based on additional properties
  if (hasArrayProperty) {
    return "row-span-3"
  } else if (additionalProperties.length > 0) {
    return "row-span-2"
  } else {
    return "row-span-1"
  }
}
</script>
