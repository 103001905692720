<template>
  <BaseCard class="p-4 md:p-6">
    <div class="flex flex-col gap-2">
      <div class="flex items-center gap-4">
        <div
          class="flex size-8 items-center justify-center rounded-lg bg-primary-50"
        >
          <BaseIcon class="text-primary" :name="icon"></BaseIcon>
        </div>
        <p class="text-xl font-medium">{{ title }}</p>
        <BaseBadge :color="getBadgeProps(service).color">
          {{ getBadgeProps(service).status }}
        </BaseBadge>
      </div>
      <div v-if="serviceValue">
        <div
          class="grid grid-cols-3 gap-4 rounded-xl border border-gray-100 py-4"
        >
          <CardContent
            :title="formattedValue"
            class="px-4"
            :content="serviceProperty"
          />
        </div>
      </div>
      <slot v-else />
    </div>
  </BaseCard>
</template>

<script setup>
import {
  BaseCard,
  BaseIcon,
  BaseBadge,
} from "@repowerednl/ui-component-library"
import CardContent from "@/components/information/CardContent.vue"
import { computed } from "vue"
import { formatCurrency } from "@/services/formatterService.js"

const props = defineProps({
  icon: { type: String, required: true },
  title: {
    type: String,
    required: true,
  },
  service: {
    type: Boolean,
    default: false,
  },
  serviceProperty: {
    type: String,
    default: "",
  },
  serviceValue: {
    type: [Number, String],
    default: "",
  },
  valueFormat: {
    type: String,
    default: "number",
    validator: (value) => {
      return ["currency", "number"].includes(value)
    },
  },
})

const formattedValue = computed(() => {
  return props.valueFormat === "currency"
    ? formatCurrency(props.serviceValue)
    : props.serviceValue
})

const getBadgeProps = (isActive) => ({
  color: isActive ? "primary" : "danger",
  status: isActive ? "Actief" : "Inactief",
})
</script>
