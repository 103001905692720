<template>
  <div class="flex flex-col gap-4">
    <TextTitle class="mb-2 md:mb-0">Instellingen</TextTitle>
    <!-- User details -->
    <BaseCard class="flex flex-col gap-4 p-6">
      <!-- Card title -->
      <div class="flex justify-between">
        <TextSubtitle>Gebruiker</TextSubtitle>
        <BaseButton v-if="!editingUser" small @click="editingUser = true">
          Wijzigen
        </BaseButton>
        <BaseButton
          v-else
          color="secondary"
          small
          class="text-danger"
          @click="cancelEditingUser"
        >
          Annuleer
        </BaseButton>
      </div>
      <div class="flex max-w-96 flex-col gap-4">
        <!-- Full name -->
        <form @submit.prevent>
          <InputField
            v-model="fullName"
            label="Naam"
            :disabled="!editingUser || fullnamePending"
          >
            <BaseButton
              v-if="fullName !== userStore.fullName"
              :loading="fullnamePending"
              @click="submitFullName"
            >
              Wijzig
            </BaseButton>
          </InputField>
        </form>
        <!-- Email -->
        <form @submit.prevent>
          <InputField
            v-model="email"
            type="email"
            label="E-mailadres"
            :state-error="validEmailError"
            :helper-text="validEmailError ? 'Ongeldig e-mailadres' : null"
            :disabled="!editingUser || emailPending || emailSent"
            @blur="showEmailValidation = true"
          >
            <BaseTooltip
              v-if="email !== userStore.email"
              description="De wijziging wordt definitief gemaakt via een bevestingsemail op het nieuwe adres."
            >
              <BaseButton
                class="w-[100px]"
                :loading="emailPending"
                @click="submitEmailRequest"
              >
                Bevestig
              </BaseButton>
            </BaseTooltip>
          </InputField>
        </form>
        <MessageComponent v-if="emailSent" in-card>
          <p>
            Er is een bevestigingsmail verstuurd naar
            <span class="font-bold">{{ emailSent }}</span
            >. Gebruik de link in de mail om het nieuwe e-mailadres te
            bevestigen.
          </p>
        </MessageComponent>
      </div>
    </BaseCard>
    <!-- Password -->
    <BaseCard ref="passwordCardRef" class="flex flex-col gap-4 p-6">
      <!-- Card title -->
      <div class="flex justify-between">
        <TextSubtitle>Wachtwoord</TextSubtitle>
        <BaseButton v-if="!editingPassword" small @click="startEditingPassword">
          Wachtwoord wijzigen
        </BaseButton>
        <BaseButton
          v-else
          color="secondary"
          small
          class="text-danger"
          @click="cancelEditingPassword"
        >
          Annuleer
        </BaseButton>
      </div>
      <!-- Current password placeholder -->
      <div v-if="!editingPassword" class="flex max-w-96 flex-col gap-4">
        <InputField
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
          label="Wachtwoord"
          disabled
          class="max-w-96"
        />
      </div>
      <!-- Password change form -->
      <form v-else class="flex max-w-96 flex-col gap-4" @submit.prevent>
        <InputField
          v-model="currentPassword"
          type="password"
          label="Huidig wachtwoord"
          :state-error="passwordIncorrect"
          :helper-text="passwordIncorrect ? 'Wachtwoord is onjuist' : null"
          :disabled="passwordPending"
          class="max-w-96"
        />
        <!-- Password instructions -->
        <div class="flex flex-col gap-2">
          <p>
            Kies een wachtwoord dat je nergens anders gebruikt met de volgende
            eigenschappen:
          </p>
          <ul class="list-inside list-disc">
            <li>Bevat minimaal 8 tekens</li>
            <li>Mag niet enkel uit cijfers bestaan</li>
            <li>Geen veelvoorkomend wachtwoord</li>
            <li>Mag niet op het e-mailadres lijken</li>
          </ul>
        </div>
        <InputField
          v-model="newPassword"
          type="password"
          label="Nieuw wachtwoord"
          :state-error="passwordIllegal"
          :helper-text="
            passwordIllegal ? 'Wachtwoord voldoet niet aan de eisen' : null
          "
          :disabled="passwordPending"
          class="max-w-96"
        />
        <InputField
          v-model="newPasswordRepeated"
          type="password"
          label="Herhaal nieuw wachtwoord"
          :helper-text="
            passwordRepeatError ? 'Wachtwoorden komen niet overeen' : null
          "
          :state-error="passwordRepeatError"
          :disabled="passwordPending"
          class="max-w-96"
        />
        <BaseButton
          class="ml-auto"
          :loading="passwordPending"
          @click="submitNewPassword"
        >
          Wijzig
        </BaseButton>
      </form>
    </BaseCard>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from "vue"
import { useRouter } from "vue-router"
import useNotificationStore from "@/stores/notificationStore.js"
import useUserStore from "@/stores/userStore.js"
import useSessionStore from "@/stores/sessionStore.js"
import {
  TextTitle,
  TextSubtitle,
  BaseCard,
  BaseButton,
  BaseTooltip,
  InputField,
} from "@repowerednl/ui-component-library"
import MessageComponent from "@/components/information/MessageComponent.vue"

const router = useRouter()
const notificationStore = useNotificationStore()
const userStore = useUserStore()
const sessionStore = useSessionStore()
const passwordCardRef = ref()
const editingUser = ref(false)
const editingPassword = ref(false)

const fullName = ref(userStore.fullName)
const fullnamePending = ref(false)

const email = ref(userStore.email)
const showEmailValidation = ref(false)
const emailPending = ref(false)
const emailSent = ref(false)

const currentPassword = ref("")
const newPassword = ref("")
const newPasswordRepeated = ref("")
const passwordPending = ref(false)
const passwordIncorrect = ref(false)
const passwordIllegal = ref(false)
const showPasswordRepeatValidation = ref(false)

const validEmail = computed(() => {
  // This pattern should match the one used in the 'validate_email()' function
  // in the 'repower-django' backend.
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/
  return email.value.match(emailPattern)
})

const validEmailError = computed(
  () => showEmailValidation.value && !validEmail.value,
)

const passwordRepeatError = computed(
  () =>
    showPasswordRepeatValidation.value &&
    newPassword.value !== newPasswordRepeated.value,
)

function cancelEditingUser() {
  editingUser.value = false
  fullName.value = userStore.fullName
  fullnamePending.value = false
  email.value = userStore.email
  showEmailValidation.value = false
  emailPending.value = false
}

function startEditingPassword() {
  editingPassword.value = true
  nextTick(() => {
    passwordCardRef.value.$el.scrollIntoView({ behavior: "smooth" })
  })
}

function cancelEditingPassword() {
  editingPassword.value = false
  currentPassword.value = ""
  newPassword.value = ""
  newPasswordRepeated.value = ""
  passwordPending.value = false
  passwordIncorrect.value = false
  passwordIllegal.value = false
  showPasswordRepeatValidation.value = false
}

function submitFullName() {
  fullnamePending.value = true
  userStore.changeFullName(
    fullName.value,
    () => {
      cancelEditingUser()
      notificationStore.pushToast(
        "Naam gewijzigd",
        `Je naam is gewijzigd naar ${fullName.value}`,
      )
    },
    (error) => {
      fullnamePending.value = false
      notificationStore.pushError(
        "Fout bij het wijzigen van de naam",
        `De naamswijziging kon niet worden doorgevoerd (code: ${error.code}).`,
        "full-name-change-error",
      )
    },
  )
}

function submitEmailRequest() {
  if (!validEmail.value) return
  emailPending.value = true
  userStore.requestEmailChange(
    email.value,
    () => {
      emailSent.value = email.value
      cancelEditingUser()
      notificationStore.pushToast(
        "E-mailadres gewijzigd",
        "Er is een e-mail verstuurd naar het nieuwe adres ter bevestiging.",
        "email-change-success",
      )
    },
    (error) => {
      emailPending.value = false
      if (error.code === 400) {
        notificationStore.pushError(
          "Ongeldig e-mailadres",
          "Gebruik een geldig e-mailadres.",
          "email-change-invalid",
        )
      } else if (error.code === 409) {
        notificationStore.pushError(
          "E-mailadres niet beschikbaar",
          "Het opgegeven e-mailadres kan niet worden gebruikt voor dit account.",
          "email-change-unavailable",
        )
      } else {
        notificationStore.pushError(
          "Fout bij het wijzigen van het e-mailadres",
          `Het e-mailadres kon niet worden gewijzigd (code: ${error.code}).`,
          "email-change-error",
        )
      }
    },
  )
}

function submitNewPassword() {
  showPasswordRepeatValidation.value = true
  if (newPassword.value !== newPasswordRepeated.value) return
  passwordPending.value = true
  userStore.changePassword(
    currentPassword.value,
    newPassword.value,
    () => {
      cancelEditingPassword()
      sessionStore.setAuthenticated(false)
      router.push({ name: "logout" })
      notificationStore.pushToast(
        "Wachtwoord gewijzigd",
        "Log in met je nieuwe wachtwoord.",
        "password-change-success",
      )
    },
    (error) => {
      passwordPending.value = false
      if (error.code === 401) passwordIncorrect.value = true
      else if (error.code === 400) passwordIllegal.value = true
      else if (error.code === 429) {
        notificationStore.pushError(
          "Te veel pogingen",
          "Je hebt te vaak geprobeerd je wachtwoord te wijzigen. Probeer het later opnieuw.",
          "password-change-throttle",
        )
      } else {
        notificationStore.pushError(
          "Fout bij het wijzigen van het wachtwoord",
          `Het wachtwoord kon niet worden gewijzigd (code: ${error.code}).`,
          "password-change-error",
        )
      }
    },
  )
}

watch(
  () => newPassword.value,
  () => (passwordIllegal.value = false),
)
</script>
