// Should be replaced byVue i18n or something similar

export default function getPhrase(key) {
  if (key in phrases) {
    return phrases[key]
  } else {
    console.warn(`Phrase with key "${key}" not found`)
    return key
  }
}

const phrases = {
  "status-unknown": "Asset status onbekend",
  "status-offline": "Asset is offline",
  "status-online": "Asset is online",
  "status-active-steering-curtailment": "Asset wordt gecurtaild",
  "status-active-steering-charging": "Asset is aan het opladen",
  "status-active-steering-discharging": "Asset is aan het ontladen",
  "status-active-steering-not-specified": "Asset wordt aangestuurd",
  "status-error": "Er is een fout opgetreden",

  "demo-disclaimer":
    "De getoonde resultaten zijn gebaseerd op simulaties met historische data om de services van Repowered te demonstreren.",
}
