const configs = {
  "dev-json": {
    API_URL: "http://localhost:3000",
  },
  "dev-django": {
    API_URL: "http://localhost:8080",
  },
  test: {
    API_URL: "repowered.test",
  },
  dev: {
    API_URL: "",
  },
  qa: {
    API_URL: "",
  },
  prod: {
    API_URL: "",
  },
}

export default configs[import.meta.env.MODE] ?? configs.prod
