<template>
  <div class="box-content flex h-16 border-b border-gray-100 bg-white">
    <!-- Asset button row -->
    <div class="relative flex-1 overflow-hidden">
      <!-- Buttons -->
      <HorizontalScrollWrapper
        ref="scrollWrapperRef"
        class="flex h-full w-full items-center gap-2 px-4 md:px-10"
      >
        <LoadingWrapper compact :loading="meteringPointsStore.loadingAssets">
          <!-- Search button -->
          <BaseButton
            v-if="meteringPointsStore.assets.length > 0"
            color="secondary"
            small
            @click="onSearchClicked"
          >
            <BaseIcon name="search" class="text-gray-900" />
          </BaseButton>
          <!-- Asset buttons -->
          <BaseButton
            v-for="asset in meteringPointsStore.assetsByPortfolio[
              filterStore.currentPortfolio?.id
            ]"
            :key="asset.id"
            color="secondary"
            small
            @click="onAssetClicked(asset)"
          >
            <StatusIndicatorComponent :asset no-tooltip />
            <span class="mx-1">{{ asset.name }}</span>
          </BaseButton>
        </LoadingWrapper>
      </HorizontalScrollWrapper>
      <!-- Gradients to indicate more content -->
      <div
        class="pointer-events-none absolute left-0 top-0 h-full w-5 bg-gradient-to-r from-white via-white via-0% to-white/0 md:w-10 md:via-25%"
      />
      <div
        class="pointer-events-none absolute right-0 top-0 h-full w-5 bg-gradient-to-r from-white/0 via-white via-100% to-white md:w-10 md:via-75%"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import useFilterStore from "@/stores/filterStore.js"
import useMeteringPointsStore from "@/stores/repower/meteringPointsStore.js"
import {
  BaseButton,
  BaseIcon,
  LoadingWrapper,
} from "@repowerednl/ui-component-library"
import StatusIndicatorComponent from "@/components/information/StatusIndicatorComponent.vue"
import HorizontalScrollWrapper from "@/components/utilities/HorizontalScrollWrapper.vue"

const route = useRoute()
const router = useRouter()
const filterStore = useFilterStore()
const meteringPointsStore = useMeteringPointsStore()
const scrollWrapperRef = ref()

function onSearchClicked() {
  router.push({ name: "results", query: { search: null } })
}

function onAssetClicked(asset) {
  filterStore.currentMeteringPoint = asset
  router.push({ name: "assets-analysis" })
}

// Reset asset bar scroll position when the route changes
watch(
  () => route.name,
  () => {
    if (scrollWrapperRef.value) {
      scrollWrapperRef.value.resetPosition()
    }
  },
)
</script>
