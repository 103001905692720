import { defineStore } from "pinia"
import { fetchApiJson } from "@/services/repowerAPIService.js"
import {
  usePortfolioStoreFactory,
  useAllocationPointStoreFactory,
  useAssetStoreFactory,
} from "@/stores/repower/meteringPointStoreFactories.js"

export default defineStore("meteringPointsStore", {
  state: () => ({
    portfolios: [],
    allocationPoints: [],
    assets: [],
    loadingAssets: false,
  }),
  getters: {
    allocationPointsByPortfolio: (state) => {
      const grouped = {}
      for (const allocationPoint of state.allocationPoints) {
        if (!grouped[allocationPoint.portfolioId]) {
          grouped[allocationPoint.portfolioId] = []
        }
        grouped[allocationPoint.portfolioId].push(allocationPoint)
      }
      return grouped
    },
    assetsByPortfolio: (state) => {
      const grouped = {}
      for (const asset of state.assets) {
        if (!grouped[asset.portfolioId]) {
          grouped[asset.portfolioId] = []
        }
        grouped[asset.portfolioId].push(asset)
      }
      return grouped
    },
  },
  actions: {
    loadPortfolios(onSuccess, onFail) {
      fetchApiJson("/v2/portfolios/")
        .then((data) => {
          this.portfolios = []
          for (const portfolio of data) {
            const portfolioStore = usePortfolioStoreFactory(portfolio.id)
            portfolioStore.setMeteringPoint(portfolio)
            this.portfolios.push(portfolioStore)
          }
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
    loadAllocationPoints(onSuccess, onFail) {
      fetchApiJson("/v2/allocation-points/")
        .then((data) => {
          this.allocationPoints = []
          for (const allocationPoint of data) {
            const allocationPointStore = useAllocationPointStoreFactory(
              allocationPoint.id,
            )
            allocationPointStore.setMeteringPoint(allocationPoint)
            this.allocationPoints.push(allocationPointStore)
          }
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
    loadAssets(onSuccess, onFail) {
      this.loadingAssets = true
      fetchApiJson("/v2/assets/")
        .then((data) => {
          this.assets = []
          for (const asset of data) {
            const assetStore = useAssetStoreFactory(asset.id)
            assetStore.setMeteringPoint(asset)
            this.assets.push(assetStore)
          }
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
        .finally(() => {
          this.loadingAssets = false
        })
    },
    findPortfolio(id) {
      return this.portfolios.find((p) => p.id === id)
    },
  },
})
